import http from "../../utils/http"
/**
 * คอลเลกชันสินค้าของฉัน
 */
export function goodsCollect(params) {
    return http({
        url: "/api/goodscollect/page",
        data: params,
        forceLogin: true
    })
}
/**
 * เลิกคั่นหน้าสินค้า
 */
export function deleteGoods(params) {
    return http({
        url: "/api/goodscollect/delete",
        data: params,
        forceLogin: true
    })
}
/**
 * ยกเลิกคอลเลกชันร้านค้า
 */
export function deleteShop(params) {
    return http({
        url: "/api/shopmember/delete",
        data: params,
        forceLogin: true
    })
}